<template>
  <div>
    <div class="search">
      <el-row>
        <el-col :span="5">
          <div class="search_input">
            <el-input
              v-model="condition"
              placeholder="请输入查找关键字"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="search_input">
            <el-button class="btn" type="primary" @click="reset"
              >重置</el-button
            >
            <el-button class="btn" type="primary" @click="getList"
              >搜索</el-button
            >
          </div>
        </el-col>
        <el-col :offset="12" :span="1">
          <div class="search_input">
            <el-button
              class="btn"
              icon="el-icon-plus"
              type="primary"
              @click="handleCodeAdd"
              >新增</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <template>
          <el-table-column type="index" align="center" width="150" label="序号">
          </el-table-column>
          <el-table-column prop="name" align="center" label="场所名称">
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="生成时间">
          </el-table-column>
          <el-table-column prop="codeUrl" align="center" label="场所码">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px; margin-top: 20px"
                :src="scope.row.codeUrl"
              >
              </el-image>
            </template>
          </el-table-column>
        </template>
        <el-table-column fixed="right" width="250" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              plain
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      custom-class="dialog"
    >
      <el-row>
        <el-col :span="6">
          <div class="name">场所名称：</div>
        </el-col>
        <el-col :span="12">
          <el-input placeholder="请输入场所名称" v-model="locationName">
            <el-button slot="append" @click="onSubmit"
              >生成</el-button
            ></el-input
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <div class="name">场所码：</div>
        </el-col>
        <el-col :span="12">
          <el-image
            style="width: 100px; height: 100px; margin-top: 20px"
            :src="url"
          >
          </el-image>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../components/MoTable/MoTable";
export default {
  name: "codeManagement",
  components: {
    MoTable,
  },
  data() {
    return {
      // 新增
      dialogVisible: false,
      locationName: "",
      condition: "",
      title: "",
      tableData: [
        {
          name: "测试",
          age: "15",
          time: "2017-02-01",
          url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          srcList: [
            "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
          ],
        },
        {
          name: "测试222",
          age: "12",
          time: "2017-02-01",
          url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          srcList: [
            "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
            "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
          ],
        },
      ],
      url: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.condition = "";
      this.getList();
    },
    async getList() {
      const res = await this.$get("/code/getCodeList", {
        ...this.page,
        condition: this.condition,
      });
      console.log(res);
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    async onSubmit() {
      if (this.locationName) {
        const res = await this.$get("/code/createLocation", {
          locationName: this.locationName,
        });
        console.log(res);
        if (res.flag) {
          this.url = res.data;
          this.$message.success(res.message);
        } else {
          this.$message.error("服务器繁忙");
        }
      } else {
        this.$message.error("请输入场所");
      }
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          this.codeDelete(row);
        })
        .catch((_) => {});
    },
    async codeDelete(row) {
      const res = await this.$get("/code/deleteLocationCode/?id=" + row.id);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    handleCodeAdd() {
      this.title = "新增场所";
      this.dialogVisible = true;
    },
    handleEdit() {
      this.title = "场所修改";
      this.dialogVisible = true;
    },
    handleCurrentChange(n) {
      this.page.pageNum = n;
      this.getList();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.url = "";
          this.locationName = "";
          this.dialogVisible = false;
          this.getList();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  .search_input {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
.name {
  line-height: 40px;
}
.table {
  margin-top: 20px;
  // height: 100%;
  background-color: #fff;
  // /deep/ .has-gutter {
  //   height: 78px;
  //   tr {
  //     th {
  //       background-color: #e9eef3;
  //     }
  //   }
  // }
  .pagination {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
    /deep/ .el-pagination {
      .el-pagination__sizes {
        margin-right: 100px;
      }
      .el-pagination__jump {
        margin-right: 100px;
      }
    }
  }
}
</style>